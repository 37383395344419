import Constants from "expo-constants";
import knowsley from "../../languages/en/knowsley";

let councilDomain = filterClientFromDomain();

function setCouncilDomain(value: string) {
    councilDomain = value;
}

function getCouncilDomain() {
    return councilDomain;
}

function getDomainLanguageList() {
    return ['common', 'hounslow', 'knowsley', 'lambeth', 'oldham'];
}

function getDomainList() {
    return ['hounslow', 'lambeth', 'oldham', 'knowsley'];
}

function getPrefixByDomain(){
    switch (councilDomain) {
        case 'hounslow': return 'NJ';
        case 'knowsley': return 'KI';
        case 'lambeth': return 'TT';
        case 'oldham': return 'OD';
        default: return 'CC'
    }
}

function filterClientFromDomain() {
    let url = Constants.experienceUrl;
    let client = 'ccp';

    if(url.includes('lambeth')) {
        client = 'lambeth'
    }
    if(url.includes('hounslow')) {
        client = 'hounslow'
    }
    if(url.includes('knowsley')) {
        client = 'knowsley'
    }
    if(url.includes('oldham')) {
        client = 'oldham'
    }
    return client;
}

function isPrelive(): boolean {
    let url = Constants.experienceUrl;
    return url.indexOf("prelive") != -1;
}

export const navigationService = {
    setCouncilDomain, getCouncilDomain, getDomainList, getDomainLanguageList, getPrefixByDomain, isPrelive
};
